@import url('https://fonts.googleapis.com/css2?family=Vollkorn&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merienda&display=swap');

@media (prefers-color-scheme: light) {
  body {
    background: #FFF;
    /* color: #333; */
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background: var(--night-blue2);
    color: #FFF;
  }

  footer {
    background-color: var(--night-blue2) !important;
    color: var(--berge);
  }
  .footer{
    border-top: 1.5px solid red;
    box-shadow: rgba(247, 248, 249, 0.8) 0px 10px 10px -10px!important;
  }
  footer .logo{
    color: var(--red)!important;
  }

  .contacts {
    background-color: var(--night-blue2) !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  span,.social-text,.nav-link {
    color: var(--berge) !important;
  }
  .menu-text{
    color: var(--night-blue2)!important;
  }

  #header {
    /* border: 2px solid green; */
    box-shadow: rgba(247, 248, 249, 0.8) 0px 10px 10px -10px;
  }
  
  .service:hover,
  .servic:hover,.service:hover .icone,.servic:hover .icone {
    box-shadow: rgba(247, 248, 249, 0.8) 1px 0px 25px 1px!important;
    /* box-shadow: rgba(247, 248, 249, 0.8) 0px 15px 15px -3px; */
  }
  .login,.signup{
    box-shadow: rgba(17, 17, 26, 0.9) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px!important;
  }
  .PhoneInputInput {
    background: var(--night-blue2);
  }
  .summary .warning{
    background-color: var(--night-blue2)!important;
  }
  .promocode{
    background-color: var(--night-blue) !important;
  }
  .sendmoney .form {
    box-shadow: rgba(17, 17, 26, 0.9) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px!important;
  }
  .lang {
    box-shadow: rgba(17, 17, 26, 0.9) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px!important;
    background-color: transparent!important;
  }
  .buycrypto .form,.buycrypto .rate{
    box-shadow: rgba(17, 17, 26, 0.9) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px!important;
  }
  .sellcrypto .form,.sellcrypto .rate{
    box-shadow: rgba(17, 17, 26, 0.9) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px!important;
  }
  .choix1{
    background-color: darkgray!important;
  }
  .disjoint{
    color: #842029!important;
  }
  .testimonials{
    background-color: lightslategrey!important;
  }
  .testimonial{
    background-color: var(--night-blue2)!important;
  }
  .partners{
    background-color: lightslategrey!important;
    color: white;
  }
  .lang-item,.langs{
    background-color: var(--night-blue2)!important;
  }
  .dash{
    background-color: var(--night-blue2)!important;
  }
  .react-responsive-modal-modal{
    background-color: var(--night-blue2)!important;
    color: var(--berge)!important;
  }
  #dashboard{
    box-shadow: rgba(17, 17, 26, 0.9) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px!important;
  }
  .sidebar .menuItem:focus,.menuItem:active {
    background-color: #553535;
  }
  .sidebar .active {
    background-color: #7a4b4b!important;
    /* background-color: #9110100a; */
    margin-left: 0;
  }
  .MuiMenuItem-root {
    color: var(--night-blue2)!important;
  }
  .text-us{
    color: var(--night-blue2)!important;
  }
  .more-section{
    background-color: #4b4848!important;
  }
  label{
    color: #03b2cb!important;
  }
  .MuiInputBase-input,.PhoneInputInput {
    color: var(--berge)!important;
  }
  b{
    color: white;
  }
  .ipp{
    color: white;
  }
  .choix1 .lig{
    color: var(--red);
  }
  .about-descrit{
    color: var(--berge)!important;
  }
  .modal-confirm,.operation-id {
    background-color: var(--night-blue2)!important;
  }
  .content-modal h2 {
    color: #0e2d39!important;
  }
  .forget .forget-resend .resend-content  {
    text-align: center;
    color: #0e2d39!important;
  }
  .ret{
    color: var(--berge);
  }
  .notfound .error {
    width: 50%;
    display: flex;
    background-color: black;
  }
  .notfound .error {
    display: flex;
    background: black!important;
    width: 70%;
  }
  .notfound span {
    color: var(--red)!important;
  }
  .clor{
    color: #0e2d39;
  }
  .modal-details {
    background-color: var(--night-blue2);
  }
  .modal-container {
    color: var(--beige);
    background-color: var(--night-blue2);
  }
  .plex, .plex span{
    color: var(--beige)!important;
  }
  
}

:root {
  --no-value: none;
  --white: #fff;
  --berge: #f6efef;
  --red: #cc1616;
  --red-transparent: #cc161693;
  --blue: blue;
  --green: #2ecc71;
  --yellow: #ffee01;
  --night-blue2: #0e2d39;
  --night-blue: #0f394c;
  --transparent: #00000029;
  --transparent2: #c5ced7;
  --white-gray: #f9f9f9;
  --gray: #707070;
  --black: #000000;
  --transparency: transparent;
  --police: 'Vollkorn', serif;
  --police2: 'Merienda', cursive;
}

html,
body,
header,
main,
footer,
ul {
  margin: 0px;
  padding: 0px;
}

html {
  scroll-behavior: smooth;
}

#root {
  width: 100%;
}

.approot {
  display: flex;
  flex-flow: column nowrap;
  /* j'ai du desactiver ca pour que ca scroll sous ios */
  height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
  /* border: 1px solid #000; */
}

.approotios {
  display: flex;
  flex-flow: column nowrap;
  /* height: 100vh; */
  overflow-x: hidden;
  scroll-behavior: smooth;
}

li {
  list-style-type: none;
}

a {
  color: var(--night-blue);
  text-decoration: none;
}

button {
  cursor: pointer;
  outline: 0;
}

/* travailler la scroll bar */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(150deg, #fff1e0 18%, #cc1616 77%);
  border-radius: 3px;
}

/*::-webkit-scrollbar-thumb:hover {
  background: #b3afb3;
}*/
::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 5px;
  box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}

/* retirer les fleche sur les input number */

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Chrome */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Opéra*/
input::-o-inner-spin-button,
input::-o-outer-spin-button {
  -o-appearance: none;
  margin: 0
}

/*-- le modal--*/
.react-responsive-modal-modal {
  border-radius: 2%;
  margin: 0;
  padding: 0 !important;
}