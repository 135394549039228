.services {
    padding: 30px 20px;
    text-align: center;
}
.services h2 {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    font-size: 2.2em;
}
.services h2 span {
    display: block;
    width: 150px;
    margin-top: 8px;
    background: var(--red);
    border: 3px solid var(--red);
    border-radius: 10px;
}
.service,.servic {
    position: relative;
    width: 75%;
    margin: 50px auto;
    padding: 10%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
    border-radius: 5px;
}
.icone {
    position: absolute;
    color: var(--white);
    background: var(--red);
    width: 75px;
    height: 75px;
    font-size: 30px;
    border-radius: 50%;
    /* top: -35px;
    left: 42%; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.service:hover .icone {
    position: absolute;
    color: var(--white);
    background: var(--night-blue);
    width: 75px;
    height: 75px;
    font-size: 30px;
    border-radius: 50%;
    top: -35px;
    left: 42%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.servic:hover .icone {
    position: absolute;
    color: var(--white);
    background: var(--night-blue);
    width: 75px;
    height: 75px;
    font-size: 30px;
    border-radius: 50%;
    /* top: -35px;
    left: 42%; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.ilcon{
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0px, -30px);
}

@media screen and (min-width: 568px) {
    .ilcon{
        transform: translate(0px, -55px);
    }
}
@media screen and (min-width: 768px) {
    .services-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
    }
    .icone{
        font-size: inherit;
    }
    .service {
        width: 30%;
        padding: 5%;
        font-size: 1.5em;
    }
    .servic {
        width: 30%;
        padding: 5%;
        font-size: 1.259em;
    }
    .service:hover {
        cursor: pointer;
        transform: translateY(-15px);
        transition: all 0.5s ease-out;
        background-color: var(--night-blue);
        color: var(--white);
    }
    .servic:hover {
        cursor: pointer;
        transform: translateY(-15px);
        transition: all 0.5s ease-out;
        background-color: var(--night-blue);
        color: var(--white);
    }
    .ilcon{
        transform: translate(0px, -40px);
    }
}

@media screen and (min-width: 1200px) {
    .service {
        width: 20%;
        padding: 5%;
        font-size: 1.3em;
    }
    .servic{
        font-style: 1.2em;
        width: 20%;
        padding: 5%;
    }
    .service h3, .service p {
    }
    .countries img {
        cursor: pointer;
    }
    .ilcon{
        transform: translate(0px, -60px);
    }
}
