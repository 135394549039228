.hero {
    padding: 0% 5%;
    margin-bottom: 60px;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    /* font-family: var(--police2); */
}
.hero>img, .hero>video{
    display: block;
    width: 95%;
    margin: 55px auto;
    transition: all 2s ease-out;
}
.hero h1 {
    width: 80%;
    font-size: 1.5em;
    color: var(--night-blue);
    transition: all 2s ease-out;
}
.hero p {
    font-size: 1.2em;
    color: var(--night-blue);
    /* font-weight: lighter; */
    transition: all 2s ease-out;
}
.hero button {
    display: block;
    border: none;
    max-width: 50%;
    background: inherit;
    color: var(--white);
}
.hero button:hover{
    color: var(--red);
}
.hero .btn-flip{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 4% 5%;
    width: 50%;
    max-width: 140px;
    color: var(--red);
    border: 2px solid var(--red);
    border-radius: 10px;
    font-size: 1.4em;
    font-weight: bold;
    overflow: hidden;
    transition: all 0.6s ease-out;
    /* transform: translateY(-10px); */
}
.hero .btn-flip:hover{
    color: var(--white);
}
/* apparition horizontale*/
/* .hero .btn-flip::before{
    background-color: var(--red);
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%,-50%);
    transition: all 0.6s ease-out;
    width: 110%;
    height: 110%;
} */

/* -- apparition oblique --*/
.hero .btn-flip::before{
    background-color: var(--red);
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%,-50%) rotate(31deg);
    transition: all 0.6s ease-out;
    width: 0%;
    height: 250%;
}
.hero .btn-flip:hover::before{
    width: 300%;
    overflow: hidden;
}

.hero .country {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}
.hero .country img {
    display: block;
    width: 15%;
    max-width: 100px;
    max-height: 105px;
    margin: 0px;
}

@media screen and (min-width: 768px) {
    .hero {
        padding:0% 4%;
    }
    .hero img, .hero>video {
        width: 80%;
    }
    .presentation {
        width: 80%;
        transition: all 2s ease-out;
    }
    .hero button {
        padding: 2%;
    }
    
}

@media screen and (min-width: 1200px) {
    .hero {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        min-height: 86vh;
        margin-bottom: 10px;
    }
    .hero > img, .hero>video {
        min-width: 300px;
        margin-left: 30px;
    }
    .presentation {
        flex-basis: 55%;
        order: -1;
        margin-top: -50px;
    }
    .countries{
        height: 80px;
    }
    .hero h1 {
        font-size: 1.8em;
        margin-top: -10px;
    }
    .hero p {
        font-size: 1em;
        margin-top: -10px;
        margin-bottom: 30px;
    }
    .hero button {
        padding: 3% 4%;
    }
    .hero .countries h3 {
        font-size: 1.4em;
    }
}

@media screen and (min-width: 1500px) {
    /* .hero{
        padding: 100px auto!important;
    } */
    .hero img, .hero>video {
        flex-basis: 55%;
        /* transform: translateY(-130px); */

    }
    .presentation {
        flex-basis: 50%;
        /* transform: translateY(30px); */
    }
    .hero h1 {
        font-size: 2.3em;
    }
    .hero p {
        font-size: 1.4em;
        margin-bottom: 45px;
    }
    .hero button {
        font-size: 2em;
    }
    .hero .countries{
        width: 88%;
        height: 100px;
    }
    .hero .countries h3 {
        font-size: 2em;
    }
    .country img{
        transform: translateX(0);
        width: 50px;
    }
    .hero .country img {
        display: block;
        width: 7%;
        max-width: 80px;
        max-height: 82px;
        margin: 0px;
    }
    .hero .btn-flip {
        font-size: 1.4em;
        padding: 3.5% 5%;
    }
}

.to-up {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 50px;
    height: 50px;
    right: 30px;
    top: 85vh;
    z-index: 100;
    background: var(--red-transparent);
    border-radius: 50%;
    cursor: pointer;
    transition: all 2s ease-out;
    transform: translate(150px, 0px);
}
.to-up.appear {
    transform: translate(0px, 0px);
    border-color: var(--red);
}
.to-up a {
    color: var(--white);
}