.reset{
    padding: 15px 30px;
}
.reset .reset-form .MuiButton-label{
    display:flex!important;
    justify-content: center!important;
    align-items: center!important;
}
.reset .reset-form .MuiButton-root{
    font-weight: 400!important;
    color: var(--white)!important;
}
.reset .reset-form .MuiButtonBase-root{
    background-color: var(--red)!important;
}
.reset .reset-form .MuiButton-root:disabled {
    background-color: var(--transparent2)!important;
}
.forget .forget-resend .resend-content{
        padding: 15px;
        /* font-family: 'montserat'; */
        color: #262626;
        display: block;
        margin: 0 auto;
    }
.reset-form{
    /* font-family: 'montserat'; */
    width:85%;
    min-height: 50vh;
    margin: 0 auto 15px auto;
    padding: 16px 16px 12px 16px;
    border: 1px solid #eeeeee;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
.forget .MuiButton-text {
    padding: 6px 8px;
    width: 100%;
    background: var(--red);
    color: var(--white);
}
.reset-title{
    margin-top:50px;
    text-align: center;
    color: #262626;
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.53em;
    margin-block-end: 0.53em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.reset-paragraph{
    color: #262626;
    font-size: 1rem;
    font-family: 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.5;
}
.reset-form .login-form .form-groupe{
    margin: 12px 0 25px 0;
    display: flex;
}
.reset-btn{
    margin: 7px 0 10px 0;
    color: var(--white);
    padding: 7px 15px;
    border-radius: 4px;
    border:none;
    font-size:18px;
    width: 95%;
    background-color: var(--red);
    font-weight: 900;
    line-height: 1.2;
    letter-spacing:  0.02857em;
    cursor: pointer;
    font-family: 'roboto';
    transition: all 1s ease-out;
}
.reset-btn:disabled{
    background-color: var(--transparent2);
}

/*--------------------------------------
styling resend components
--------------------------------------*/
.forget .forget-resend {
    width:85%;
    min-height: 50vh;
    margin: 45px auto 45px auto;
    padding: 16px 16px 12px 16px;
    border: 1px solid #eeeeee;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
.forget .forget-resend .resend-content{
    padding: 15px;
    margin-top:10px;
    /* font-family: 'montserat'; */
    color: #262626;
    display: block;
    
}
.forget .forget-resend .resend-content h1{
    margin: 15px;
    text-align: center;
}
.forget .forget-resend .resend-content h2,h3,hr{
    margin: 10px;
}
.forget .forget-resend .resend-content h2,h3,hr{
    text-align: center;
}
.forget .forget-content .MuiButton-text {
    padding: 6px 8px;
    width: 100%;
    color: var(--white);
    /* background: radial-gradient(#cc1616, transparent); */
    background: var(--red);
}
.login-show-pass{
    transform: translate(-15px,30px);
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    /*--site mode tablette--*/
    .reset{
        margin: 25px auto;
    }
    .reset-form{
        /* font-family: 'montserat'; */
        width: 35%;
        margin: 0 auto 20px auto;
        padding: 5px auto;
        border: 1px solid #eeeeee;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,rgba(17, 17, 26, 0.05) 0px 8px 32px;
    }
    .reset-title{
        text-align: center;
        /* font-family: 'montserat'; */
        color: #262626;
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.53em;
        margin-block-end: 0.53em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    .reset-btn{
        margin: 7px 0 30px 0;
        padding: 7px 30px;
        border-radius: 4px;
        width: 50%;
        background-color: var(--red);
    }
    .forget .forget-content .MuiButton-text {
        padding: 6px 8px;
        width: 100%;
        color: white;
        /* background: radial-gradient(#cc1616, transparent); */
        background: var(--red);
    }
    .forget .forget-resend {
        width: 60%;
    }
    .forget .forget-resend .resend-content{
        padding: 15px;
        /* font-family: 'montserat'; */
        color: #262626;
        display: block;
        margin: 0 auto;
    }
    .forget .forget-resend {
        margin: 85px auto;
    }

}

@media only screen and (min-width: 992px) {
    /*--site mode tablette--*/
    
    .reset{
        padding: 15px 30px;
    }
    .reset-form{
        /* font-family: 'montserat'; */
        width: 35%;
        margin: 0 auto 5px auto;
        padding: 2% 3%;
        border: 1px solid #eeeeee;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,rgba(17, 17, 26, 0.05) 0px 8px 32px;
    }
    .reset-title{
        text-align: center;
        /* font-family: 'montserat'; */
        color: #262626;
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.53em;
        margin-block-end: 0.53em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
    }
    .reset-btn{
        display: block;
        height: 50px;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        color: var(--white);
        width: 50%;
        background-color: var(--red);
    }
    .reset .reset-form .MuiButton-root{
        
        font-size: 19.3px;
        color: var(--white)!important;
    }

    /*--------------------------------
    styling resend components
    --------------------------------*/
    .forget .forget-resend {
        width: 35%;
        margin: 50px auto 50px auto;
        padding: 5px 10px;
        border: 1px solid #eeeeee;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,rgba(17, 17, 26, 0.05) 0px 8px 32px;
    }
    .forget .MuiButton-text {
        padding: 6px 8px;
        width: 100%;
        background: var(--red);
        color: var(--white);
    }
}