.cookie{
    background-image: url('./transferer_argent_mobile_avec_bitcoin.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 35%;
    width: 25%;
    min-width: 300px;
    max-width: 500px;
    color: var(--white);
    background-color: var(--black);
    border-radius: 8px;
    display: block;
    text-align: left;
    box-shadow: rgba(245, 242, 242, 0.984) 0px 4px 24px, rgba(230, 227, 227, 0.989) 0px 2px 6px;
    border: none;
    z-index: 1000;
    position: fixed;
    left: 2%;
    bottom: 3%;
    animation: cookanim 1s ease-out;
}
.cookie-2{
    display: flex;
    align-items: center;
    border-top: 1px solid gray;
}
.cookie-1{
    padding: 2px 15px 15px 15px;
}
.cookie-3,.cookie-4{
    width: 50%;
    padding: 15px;
    text-align: center;
    background-color: rgba(55, 53, 53, 0.177);
    cursor: pointer;
}
.cookie-3:active,.cookie-4:active{
    background-color: rgba(162, 162, 158, 0.687);
}
.cookie-4{
    border-right: 1px solid gray;
    border-bottom-left-radius: 8px;
    text-align: center;
}
#cookie-cook{
     /* -webkit-appearance: none; */
     visibility: hidden;
     display: none;
}
.cook-lig{
    display: flex;
    justify-content: space-between;
    min-height: 30px;
    margin: 5px 0;
    animation: cookanim2 .5s ease-in-out ;
    display: 'flex';
    flex-direction: 'column';
    justify-content: 'center';
    align-items: 'space-around';
}
.cookie-check{
    position: relative;
    display: block;
    width: 40px;
    height: 20px;
    background: var(--white);
    cursor: pointer;
    border-radius: 20px;
    overflow: hidden;
    transition: ease-in 0.5s;
    transform: translateY(5px);
}
.cookie-check:before{
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: deeppink;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: 0.5s;
}
#cookie-cook:checked ~ .cookie-check{
    background-color: var(--red);
    box-shadow: 0 0 15px 5px #ffffff;
}
#cookie-cook:checked ~ .cookie-check:before{
    transform: translateX(20px);
    background-color: #ffffff;
}
.cook-text{
    margin-bottom: -25px;
}
.cook-test2{
    margin-top: 25px;
    margin-bottom: 1rem;
    font-size: 34px;
}
.cook-text3{
    margin: 16px auto;
    display: block;
}



@keyframes cookanim{
    0%{
        transform: translateX(-100%);
        opacity: 0;
        
    }
    100%{
        transform: translateX(15%);
        opacity: 1;
    }
}
@keyframes cookanim2{
    0%{
        transform: scale(-1);
        opacity: 0;
        
    }
    100%{
        transform: scale(1);
        opacity: 1;
    }
}

@media only screen and (max-width: 600px) {
    .cook-lig{
        display: flex;
        justify-content: space-between;
        height: 70px;
        min-height: 0;
        margin: 5px 0;
        animation: cookanim2 .5s ease-in-out ;
    }
    .cook-text{
        margin-bottom: -25px;
    }
    .cook-test2{
        margin-bottom: 0;
    }
    .cook-test3 {
        margin-bottom: 5px;
    }
    .cookie-3,.cookie-4{
        width: 50%;
        padding: 9px;
    }
    .cook-text3{
        margin: 0px;
        display: inline;
    }
}