.contacts {
    padding: 5%;
    background: var(--berge);
}
.news{
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.contacts h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2em;
}
.contacts h2 {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    font-size: 1em;
    transform: translateY(-18px);
}
.contacts .finding{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    font-size: 2em;
    font-weight: bold;
}
.contacts h2 span {
    display: block;
    width: 120px;
    margin-bottom: 8px;
    background: var(--red);
    border: 3px solid var(--red);
    border-radius: 10px;
}
.social-icon {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 65px;
    margin: 5px 5px;
    color: var(--red);
    border: 2px solid var(--red);
    border-radius: 50%;
    overflow: hidden;
}
.social-icon:hover {
    color: var(--white);
    background: var(--red);
    transition: 0.5s all ease;
    border: 2px solid var(--white);
}

.social-icon img {
    display: block;
    width: 40px;
    height: 40px;
    z-index: 2;
}

.contact-container {
    font-weight: bold;
    font-size: 1.2em;
}
.contact-container > div, .details > div {
    margin: 20px 0px ;
}
.info img {
    display: block;
    width: 70%;
}
.socials {
    display: flex;
    /* flex-wrap: wrap; */
    /* border: 1px solid #000; */
    overflow-x: scroll;
    /* transform: scale(0.90); */
}
.info p {
    margin-top: 5px;
}
.contact > div {
    display: flex;
    align-items: center;
}
.newsletter {
    text-align: center;
}
.address-field {
    display: flex;
    align-items: stretch;
    transform: translateX(-5px);
}
.newsletter button {
    display: inline-block;
    width: 30%;
    height: 54px;
    background: var(--red);
    border: 1px solid var(--gray);
    border-radius: 8px;
    font-size: 1.1em;
    font-weight: bold;
    color: var(--white);
    outline: 0;
    z-index: 10;
}
.newsletter input {
    display: inline-block;
    flex: 1;
    height: 52px ;
    padding-right: 2%;
    padding-left: 6%;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 8px;
    border: 1px solid var(--gray);
    outline: 0;
    font-size: 1.1em;
    text-align: center;
    transform: translate(-20%, 0);
    transition: all 2s ease-out;
}
.contacts .form {
    font-size: 1.2em;
}
.contacts label {
    display: block;
    margin-top: 20px;
}
.ipp, .form textarea {
    width: 90%;
    display: block;
    height: 30px;
    padding: 2% 3%;
    background: var(--transparency);
    border: var(--no-value);
    border-bottom: 2px solid var(--transparent2);
    outline: 0;
    font-size: 1.1em;
}
.contacts .form button {
    display: block;
    width: 96%;
    height: 60px;
    background: var(--red);
    border: var(--no-value);
    border-radius: 8px;
    color: var(--white);
    font-size: 1.2em;
    font-weight: bold;
    transition: all 2s ease-out;
}
.contacts .form-group {
    margin: 30px 0px;
}
.socials .facebook:hover{
    background-color: #3b5998;
    color: white;
    transition: 0.5s all ease;
    border: 2px solid #3b5998;
}
.socials .twitter:hover{
    background-color: deepskyblue;
    color: white;
    transition: 0.5s all ease;
    border: 2px solid deepskyblue;
}
.socials .telegram:hover{
    background-color: deepskyblue;
    color: white;
    transition: 0.5s all ease;
    border: 2px solid deepskyblue;
}
.socials .linkedin:hover{
    background-color: rgb(17, 17, 253);
    color: white;
    transition: 0.5s all ease;
    border: 2px solid rgb(17, 17, 253);
}
.socials .youtube:hover{
    background-color: rgb(253, 17, 17);
    color: white;
    transition: 0.5s all ease;
    border: 2px solid rgb(253, 17, 17);
}
.socials .instagram:hover{
    background: #1a2a6c;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #fdbb2d, #b21f1f, #1a2a6c);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #fdbb2d, #b21f1f, #1a2a6c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    transition: 0.5s all ease;
    border: 2px solid var(--berge);
}
.MuiFormControl-root{
    margin: 5px auto!important;
}
.btn-loader{
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content:center ;
}


@media screen and (min-width: 768px) {
    .contact {
        display: flex;
        justify-content: space-between;
    }
    .newsletter input {
        transform: translate(-5%, 0);
    }
    .link-whatsapp{
        cursor: pointer;
    }
    .address-field {
        transform: translateX(5px);
    }
    .info img {
        width: 45%;
    }
}

@media screen and (min-width: 1200px) {
    .contacts {
        padding: 3%;
    }
    .contact-container {
        display: flex;
    }
    .details {
        width: 60%;
    }
    .info {
        display: inline-block;
        width: 60%;
        vertical-align: top;
    }
    .contact {
        display: inline-block;
        width: 35%;
        vertical-align: top;
    }
    .info > img {
        width: 60%;
    }
    .contact > div {
        margin: 15px 0px;
    }
    .social-icon {
        width: 50px;
        height: 50px;
        color: var(--red);
    }
    .social-icon:hover {
        width: 50px;
        height: 50px;
        color: var(--white);
        background: var(--red);
        transition: 0.5s all ease;
        border: 2px solid var(--white);
    }
    .social-icon img {
        width: 30px;
        height: 30px;
    }
    .newsletter {
        width: 85%;
    }
    .contacts .form {
        width: 40%;
    }
    .contacts .form-group {
        margin: 40px 0px;
    }
    
}