.header {
  /* border: 2px solid green; */
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  /* border-bottom: 1px solid var(--yellow); */
}
nav {
  /* retirer ca pour voir l'enseigne */
  display: flex;
  flex-flow: row nowrap;
  /* jusqu'ici */
  position: relative;
  /* max-height: 10vh; */
  transition: all 2s ease-out;
}
.banner {
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
}
.enseigne {
  display: inline-block;
  width: 190px;
  height: 60px;
  margin: 10px 20px;
}
.translate-img {
  display: inline-block;
  width: 30px;
  height: 30px;
}
.hamburger {
  position: absolute;
  /* cursor: pointer; */
  right: 4%;
  top: 50%;
  transform: translate(-5%, -60%);
  z-index: 2;
}
.line {
  width: 40px;
  height: 7px;
  background: var(--black);
  margin: 5px;
  border-radius: 5px;
  transition: all ease-out 0.5s 0.5s;
}
/* start cross animation */
.hamburger.cross .line {
  background: var(--white);
  width: 38px;
  height: 9px;
}
.hamburger.cross div:nth-child(1) {
  transform: rotate(45deg) translate(18px, -5px);
}
.hamburger.cross div:nth-child(2) {
  transform: rotate(-45deg) translate(14px, 8px);
}
.hamburger.cross div:nth-child(3) {
  opacity: 0;
}
/* end cross animation */
.nav-links {
  display: flex;
  flex-flow: column nowrap;
  padding: 80px 0 0 0;
  position: fixed;
  background: var(--red);
  height: 100vh;
  width: 100%;
  font-size: 13px;
  z-index: 1;
  clip-path: circle(10px at 90% -10%);
  -webkit-clip-path: circle(10px at 90% -10%);
  transition: all 2s ease-out;
  /* pointer-events: none; */
}
.nav-links.open {
  clip-path: circle(1300px at 90% -10%);
  -webkit-clip-path: circle(1300px at 90% -10%);
  /* pointer-events: all; */
}
.dropdown-content {
  display: none;
  margin-top: 20px;
  margin-left: 20px;
  width: 200px;
}
.dropdown-content div {
  margin-top: 15px;
  margin-left: 5%;
  text-align: left;
  font-size: 0.9em;
}
.nav-links li {
  margin-bottom: 5.75vh;
  padding-left: 15%;
  opacity: 0;
  font-size: 1.5em;
  font-weight: bold;
  color: var(--white);
  /* cursor: pointer; */
}
.nav-links li>a {
  color: var(--white);
}
.nav-links li:nth-child(1) {
  transition: all 0.5s ease 0.4s;
}
.nav-links li:nth-child(2) {
  transition: all 0.5s ease 0.8s;
}
.nav-links li:nth-child(3) {
  transition: all 0.5s ease 1.2s;
}
.nav-links li:nth-child(4) {
  transition: all 0.5s ease 1.6s;
}
.nav-links li:nth-child(5) {
  transition: all 0.5s ease 2s;
}
/* lien a retirer a retirer */
.nav-links li:nth-child(6) {
  transition: all 0.5s ease 2.4s;
}
li.fade {
  opacity: 1;
}
.nav-links button {
  padding: 5%;
  background-color: var(--white);
  font-size: 1.5em;
  font-weight: bold;
  color: var(--black);
  outline: none;
  border: none;
  border-radius: 5px;
}
.mobi-icon{
  display: inline-block;
  color: var(--white);
  width: 35px!important;
}
.visible{
  display: block;
  animation: mobileShow .3s ease-in-out both;
}
/* .invisibility{
  animation: mobileMove .3s ease-in-out both;
  display: none;
} */
.first-sub{
  display: none;
}
.tice{
  margin-right: 15px;
}
.rotation{
  transition: 0.5s;
  transform: translate(-10px, 0) rotate(180deg);
}
@media screen and (min-width: 768px) {
  .tice{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-15px,2px);
    cursor: pointer;
    margin: 0;
  }
  .tice:hover{
    background-color: #dbdbdb43;
    color: var(--night-blue)!important;
  }
  .nav-link:hover .tice{
    color: var(--white)!important;
  }
  .first-sub{
    display: block;
  }
  .mobi-icon{
    display: none;
  }
  .hamburger, .sixth {
    display: none;
  }
  /* debut des modifications */
  nav {
    justify-content: space-between;
    align-items: center;
    max-height: 15vh;
  }
  .enseigne {
    display: inline-block;
    width: 160px;
    height: auto;
    margin: 10px;
  }
  .nav-links,
  .nav-links.open {
    clip-path: none;
    flex-flow: row nowrap;
    justify-content: flex-end;
    position: relative;
    height: 8vh;
    width: 80%;
    font-size: inherit;
    margin-right: 1%;
    background-color: var(--no-value);
    /* overflow: hidden; */
  }
  .nav-links {
    align-items: center;
    padding: initial;
  }
  .nav-links li {
    padding: initial;
    opacity: 1;
    margin-left: 1.5%;
    margin-bottom: 0px;
    font-size: 1em;
    color: var(--black);
    cursor: pointer;
  }
  .nav-links li:hover {
    color: var(--red);
  }
  .nav-links li>a {
    color: inherit;
  }
  .dropdown {
    position: relative;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .dropdown-content {
    position: absolute;
    top: 100%;
    left: -110%;
    min-width: 260px;
    margin: 0px;
    z-index: 1;
    color: var(--white);
    transition: all 2s ease-out;
  }
  /* .visible{
    display: block;
  } */
  .sub {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.3);
    background-color: var(--red);
    border-radius: 5px;
  }
  
  .dropdown-content div {
    width: 100%;
    margin: auto;
    padding: 15px 0px;
    text-align: center;
  }
  .dropdown-content .sub div:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  .arrow {
    position: absolute;
    /* top: -15%; */
    top: 0%;
    left: 45%;
    display: block;
    width: 50px;
    height: 40px;
    background-color: var(--red);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    z-index: 1;
  }
  .nav-links button {
    /* width: 75px;
    height: 40px; */
    margin-left: 3%;
    padding: 1% 2%;
    background-color: var(--red);
    font-size: 1em;
    font-weight: bold;
    color: var(--white);
    outline: none;
    border: none;
    border-radius: 5px;
  }
}
@media screen and (min-width: 960px) {
  .nav-links li {
    opacity: 1;
    margin-left: 2%;
    margin-bottom: 0px;
    font-size: 1.2em;
    color: var(--black);
    cursor: pointer;
  }
  .nav-links button {
    font-size: 1.2em;
  }
}
@media screen and (min-width: 1200px) {
 
  .enseigne {
    width: 300px;
    padding-top: 15px;
  }
  .nav-links li {
    margin-left: 4%;
    font-size: 1.5em;
  }
  .dropdown-content {
    min-width: 350px;
  }
}


.beta-div {
  /* border: 1px solid #000; */
  width: 150px;
  margin: 0px auto;
  transform: translateY(10px);
  text-align: center;
  background-color: #ffee01;
  border-radius: 8px;
  color: var(--white);
  font-weight: bold;
}

@keyframes mobileShow{
  0%{
    opacity: 0;
    transform:translateY(-50px);
  }
  100%{
    opacity: 1;
    transform:translateY(5px);
  }
}
/* @keyframes mobileMove{
  0%{
    opacity: 1;
    transform:translateY(5px);
  }
  100%{
    opacity: 0;
    transform:translateY(-50px);
  }
} */
