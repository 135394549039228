
.notfound .error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height: 55vh;
  margin: 20vh auto;
  background-color: gainsboro;
}
.notfound .first {
  /* display: inline-block; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 30%;
  height: 90%;
}
.notfound .image {
  width: 25vh;
  height: 25vh;
  border-radius: 50%;
  background-color: var(--red);
  background-image: url("./emoji.png");
  background-repeat: no-repeat;
  background-position: center;
}

.notfound .second {
  /* display: inline-block; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 65%;
  height: 90%;
  margin-left: 3%;
  vertical-align: top;
  transform: translate(-10px,-90px);
}
.notfound h1 {
  font-family: nunito, sans-serif;
  font-size: 65px;
  font-weight: 700;
  transform: translateY(10px);
  margin-top: 0;
  margin-bottom: 10px;
  color: var(--night-blue);
  text-transform: uppercase;
}
.notfound h3 {
  font-family: nunito, sans-serif;
  font-size: 25px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 10px;
  color: var(--night-blue);
  text-transform: uppercase;
}
.notfound p {
  width: 60%;
  font-family: nunito, sans-serif;
  color: var(--night-blue);
  font-weight: 400;
}
.notfound span {
  font-family: nunito, sans-serif;
  display: inline-block;
  font-weight: 700;
  border-radius: 40px;
  text-decoration: none;
  color: var(--red);
  cursor: pointer;
}

@media only screen and (min-width: 768px){
  .notfound .error {
    display: flex;
    background: gainsboro;
    width: 70%;
  }
  .notfound .image {
    width: 40vh;
    height: 40vh;
  }
  
}