.cinet-close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: inline-block;
    color: white;
    font-size: 2em;
}
.cp-close {
    display: none !important;
}