.promocode{
    position: absolute;
    bottom: 2%;
    left: 10%;
    right: 12.5%;
    margin: 0 auto;
    width: 275px;
    z-index: 15;
    border-radius: 10px;
    padding: 0px 15px;
    background-color: #F6EFEF;
    /* background-color: #f8f5ec; */
    animation: visible 1s ease-in-out forwards;
}
.poli{
    margin: 40px auto 20px auto;
}
.til{
    display: block;
    margin: 0px auto;
    padding-left: 8px;
    text-align: left;
}
.inp{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 40px;
    padding: 5px 10px;
    font-size: 18px;
    border: 1.5px solid rgba(0,0,0,.34);
    border-radius: 4px;
    z-index: 2;
    cursor: pointer;
    outline: none;
}
.btncodesuccess{
    display: inline-flex!important;
    justify-content: center!important;
    align-items: center!important;
    width: 99.5%!important;
    height: 45px!important;
    border-radius: 6px!important;
    margin: 10px auto;
    background-color: var(--red);
    color: white;
    font-weight: bold!important;
    font-size: 18px!important;
    border: none;
}
.btncodesuccess:hover{
    background-color: #aa1313!important;
}
.btncodelost{
    display: inline-block;
    width: 97%;
    height: 40px;
    font-size: 16px!important;
    border-radius: 8px;
    margin: 10px auto 20px;
    background-color: rgb(95, 8, 8)!important;
    color: white;
    font-weight: bold;
    border: none;
}
.btncodelost:hover{
    background-color: rgb(83, 5, 5)!important;
}
.icopromo{
    display: block;
    font-size: 24px;
    float: right;
    text-align: right;
}
.promoerreur{
    display: flex;
    color: var(--red);
}
.frais{
    display: block;
    text-align: center;
    color: var(--red);
    font-size: 22px;
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
.hide{
    animation: invisible .5s ease-in-out forwards;
    display: none;
}

.hide{
    animation: invisible .5s ease-in-out forwards;
    display: none;
}
@media screen and (min-width: 560px) {
    .promocode{
        position: absolute;
        bottom: 2%;
        left: initial;
        right: 1.5%;
        width: 275px;
        animation: visible 1s ease-in-out both;
    }
    .hide{
        animation: invisible .5s ease-in-out forwards;
    }
}

@keyframes visible{
    0%{
        transform: translateX(5%);
        opacity: 0;
    }
    100%{
        transform: translateX(0%);
        opacity: 1;
    }
}
@keyframes invisible{
    0%{
        transform: translateX(0%);
        opacity: 1;
    }
    100%{
        transform: translateX(5%);
        opacity: 0;
    }
}

