.dash{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    z-index: 1000;
  }
  .AppGlass{
    display: grid;
    width: 97%;
    border-radius: 2em;
    background-color: var(--glass);
    overflow: hidden;
    grid-template-columns: 13rem auto 0rem;
  }

  @media only screen and (max-width: 768px) {
    .AppGlass{
      grid-template-columns: 15% 80% auto;
      overflow-y: scroll;
      grid-column-gap: 10px;
    }
    
  }