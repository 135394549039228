.pay {
  padding: 3%;
  min-height: 85vh;
}

.pay .error {
  width: 80%;
  padding: 3%;
  margin: auto;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 8px;
}

.pay .transaction {
  border: 5px solid var(--red);
  border-radius: 8px;
}

.pay .modal-head {
  padding:1% 3%;
  background: var(--red);
  font-weight: bolder;
  color: var(--white);
  text-align: center;
}

.content-modal h2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.icon-step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  border-radius: 50%;
  background: var(--red);
  font-weight: bold;
  color: var(--white);
  transform: translate(-15px, 0px);
}

/* .content-modal h3 {
  text-align: justify;
} */
.content-modal h4 a {
  color: var(--blue);
  text-decoration: underline;
}

.bulet {
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

.phone-step {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  width: 80%;
  margin: auto;
  overflow: hidden;
  transition: all 2s ease-out;
}

.phone-step img {
  display: block;
  width: 60%;
}

.backfunds {
  display: flex;
  justify-content: center;
  color: gray;
}

.pay .operation-id {
  margin: 15px 0px;
  text-align: center;
  font-size: 1.1em;
  font-weight: bold;
}

.pay .operation-id h3 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  color: var(--red);
}

h3 .op-id {
  /* display: block; */
  font-size: 1em;
  font-weight: bold;
  color: var(--red);
  cursor: pointer;
}

.deco {
  display: inline-block;
  width: 10%;
  max-width: 100px;
  height: 10px;
  margin: 0px 2%;
  background: var(--red);
}
.ret{
  cursor: pointer;
  color: gray;
}
.droit{
  display: inline-block;
  transform: translate(5px, 14px);
}
.error_pay h1{
  color: gray!important;
}
.MuiStepConnector-lineVertical {
  min-height: 0!important;
}
.op_ident{
  color: white;
  font-size: 15px;
}
.tatle{
  text-align: center;
  font-size: larger;
}

@media screen and (min-width: 768px) {
  .pay .error {
    width: 60%;
  }
  .titre_step{
    font-size: 20px!important;
  }
  .envelop{
    display: flex;
    gap: 5;
  }
  .clor{
    font-size: 13px;
  }
  
  .pay .transaction {
    width: 80%;
    margin: auto;
  }

  .content-modal {
    padding: 2% 5%;
    font-size: 1.5em;
  }

  .content-modal h2 {
    font-size: 2.5em;
  }
}

@media screen and (min-width: 1200px) {
  .pay .transaction {
    width: 75%;
    margin: auto;
  }

  .pay .operation-id h3 {
    font-size: 1.5em;
  }
}