.complete {
    width: 80%;
    min-height: 65vh;
    padding: 4%;
    margin: 20px auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: var(--night-blue);
    text-align: center;
}
.complete img {
    display: block;
    width: 100%;
}
/* .complete h3 {
    font-weight: 400;
} */
.complete button {
    display: block;
    margin: 20px auto;
    padding: 4%;
    background: var(--red);
    border: none;
    border-radius: 15px;
    font-size: 1.2em;
    font-weight: 600;
    color: var(--white);
}
.complete span {
    padding: 1%;
    color: var(--red);
    font-size: 1.2em;
    border-bottom: 1px solid var(--red);
}
.complete .rates {
    /* margin-bottom: 30px; */
}
.complete .rate img {
    display: block;
    /* width: 30%; */
    margin: auto;
    /* padding: 2%; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 8px;
}
.complete .rate h5 {
    margin-bottom: 5px;
}
.complete .bilan-button {
    display: inline-block;
    margin: 15px;
    font-size: .9em;
    text-decoration: underline;
    /* border: 1px solid #000; */
}
.complete .bilan-button:hover {
    cursor: pointer;
}
.bilan {
    min-width: 300px;
    color: var(--night-blue);
    background-color: var(--berge);
}

.bilan .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 1% 6%;
    border-top: 3px solid var(--white);
    font-size: 1em;
}
.bilan .row a {
    color: blue;
    text-decoration: underline;
    outline: none;
}
.bilan .span {
    display: block;
    padding: 15px;
    text-align: center;
    /* color: var(--green); */
}
.bilanTitle{
    padding: 25px 6%;
    margin-top: -15px;
    margin-bottom: 0;
}


@media screen and (min-width: 992px) {
    .complete {
        width: 60%;
        text-align: left;
    }
    .complete img {
        width: 50%;
    }
    .complete button {
        margin: 20px 0px;
    }
    .complete span {
        cursor: pointer;
    }
    .complete h3 {
        text-align: left;
        margin-left: 0px;
    }
    .c-content{
        display: flex;
        align-items: center;justify-content: center;
    }
    .complete .rate h5 {
        margin: 5px;
    }
    .complete .rates {
        display: flex;
    }
    .complete .rate {
        margin-right: 20px;
    }
    .bilan .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        border-top: 3px solid var(--white);
        font-size: 1.2em;
    }
}
