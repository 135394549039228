.sell-overlay {
}
/* .sell-modal {
  max-width: 80vw;
} */

.sell-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 50vh;
}
.modal-container {
  color: var(--night-blue);
  background-color: var(--berge);
}
.timer {
  font-weight: 600;
  font-size: 1.4em;
  color: var(--red);
}
.waiter {
  /* transform: translateY(-25px); */
  max-width: 400px;
  text-align: center;
}
.modal-container .waiter .titlewaiter{
  margin-top: 0;
  padding: 3%;
  font-size: 22px;
  font-weight: 600;
}
.modal-container .qrcode {
  text-align: center;
}
.modal-container .titleh3 {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  background-color: var(--night-blue);
  color: var(--white);
  padding: 15px 0;
  z-index: 5;
  opacity: 0.9;
}
.modal-details {
  background-color: var(--berge);
}
.modal-container .operation-id {
  color: var(--red);
}
.modal-container .message {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: auto;
  width: 80%;
}
.modal-details > div {
  display: flex;
  justify-content: space-between;
  /* margin: 30px 0px; */
  font-size: 1.5em;
  padding: 20px 20px;
  border-top: 1px solid aliceblue;
  border-bottom: 1px solid aliceblue;
}
.modal-container > div span:nth-child(2) {
  font-weight: 600;
  margin-left: 50px;
}
.modal-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.op-id span {
  display: block;
  font-size: 1em;
  font-weight: bold;
  color: var(--red);
  cursor: pointer;
}
.modal-controls button {
  padding: 2% 4%;
  margin: 15px;
  border: var(--no-value);
  border-radius: 5px;
  color: var(--white);
  font-size: 1.2em;
  font-weight: 600;
}
.modal-controls button:nth-child(1) {
  background: var(--red);
}
.modal-controls button:nth-child(2) {
  background: var(--night-blue);
}
.wallet {
  font-size: 0.9em;
}
.send-bloc {
  max-width: 500px;
  padding: 2%;
  text-align: center;
  font-size: 1em;
  line-height: 1.5em;
}
.send-bloc .wadd {
  word-wrap: break-word;
}
.send-bloc .havesend {
  display: flex;
}
.modal-controls button:disabled {
  cursor: not-allowed;
  background: var(--transparent2);
}

@media screen and (min-width: 992px) {
  .modal-details {
    font-size: 1.1em;
  }
  .modal-details > div {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
  }
  /* .modal-container{
    margin: 10px 30px;
  } */
  .modal-container > div span:nth-child(2) {
    font-weight: 600;
    margin-left: 150px;
  }
  .modal-controls button {
    font-size: 1.5em;
    margin: 15px 30px;
  }
  .send-bloc {
    padding: 2%;
    text-align: center;
    font-size: 1.3em;
    line-height: 1.5em;
  }
}

.icopy {
  display: inline-block;
  /* max-width: 125px; */
  padding: 0px;
  border: none;
  resize: none;
  outline: none;
  font-size: 1.1em;
  font-weight: bold;
  color: var(--night-blue);
}
