.sidebar{
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 4em;
    transition: all 300s ease;
}

.sidebar .logo{
    display: flex;
    font-weight: bold;
    font-size: 22px;
    justify-content: center;
    align-items: center;
}
.sidebar .logo>img{
   width: 10rem;
   height: 3rem; 
}
.sidebar .logo>span>span{
    color: #cc161679;
}

/*---menu sidebar--*/
.sidebar .menu{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap:2rem;
}
.sidebar .menuItem{
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 2.5rem;
    margin-left: 2rem;
    position: relative;
    transition: all 300ms ease;
    font-size: 14px;
    border-radius: 0.7rem;
}

.sidebar .menuItem:hover{
    cursor: pointer;
}
.sidebar .active{
    background-color: #cc16160a;
    margin-left: 0;
}

.sidebar .active::before{   
    content: '';
    width: 8px;
    height: 100%;
    background: #cc1616;
    margin-right: calc(1rem - 8px);
}
.sidebar .text-ico{
    color: #000!important;
}
.sidebar .text-ico2{
    color: #cc1616!important;
}

@media only screen and (max-width: 768px) {  
    .menuItem>span {
        display: none;
    }
    .sidebar .logo{
        display: none;
    }
}